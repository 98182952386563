<template>
	<div>
		<page-bar name="Galleries"></page-bar>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="galleries" :loading="listStatus === status.RUNNING"
									  calculate-widths disable-filtering disable-sort hide-default-footer
									  loading-text="Loading... Please wait">
							<template v-slot:top>
								<v-toolbar id="galleries-toolbar" class="rounded-t" flat>
									<v-spacer></v-spacer>

									<v-btn @click="createDialog = true" class="mr-2" color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<v-btn @click="listGalleries" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>
								</v-toolbar>
							</template>

							<template v-slot:item.name="props">
								<span @click="$router.push(`galleries/${props.item.id}`)"
									  class="text-decoration-underline clickable-text" v-text="props.item.name"></span>
							</template>

							<template v-slot:item.active="props">
								<v-icon v-if="props.item.active" small>
									mdi-check
								</v-icon>
								<v-icon v-else small>
									mdi-close
								</v-icon>
							</template>

							<template v-slot:item.actions="props">
								<v-menu right>
									<template v-slot:activator="{ on }">
										<v-btn v-on="on" icon small>
											<v-icon small>mdi-dots-vertical</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item @click="$router.push(`galleries/${props.item.id}`)">Edit
										</v-list-item>
										<v-list-item @click="archiveId = props.item.id">Archive</v-list-item>
									</v-list>
								</v-menu>
							</template>
						</v-data-table>
					</v-card>
				</v-col>
			</v-row>
		</v-container>

		<card-dialog :showing="createDialog" @cancel="createDialog = false" @confirmed="createGallery(gallery)"
					 :confirm-disabled="!valid" confirm-text="Save" max-width="600px" title="New Gallery">
			<template v-slot:dialog-body>
				<v-form v-model="valid" ref="galleryForm">
					<v-row dense>
						<v-col cols="12">
							<v-text-field v-model="gallery.name" :rules="[validation.required, validation.notEmpty]"
										  autocomplete="off" counter="100" dense label="Title" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="gallery.description"
										:rules="[validation.required, validation.notEmpty]" auto-grow autocomplete="off"
										counter="500" dense label="Description" outlined></v-textarea>
						</v-col>
					</v-row>
				</v-form>
			</template>
		</card-dialog>

		<card-dialog :showing="archiveId !== null" @cancel="archiveId = null" @confirmed="archiveGallery(archiveId)"
					 confirm-color="warning" confirm-text="Archive" title="Archive Gallery Image" title-color="warning">
			<template v-slot:dialog-body>
				Are you sure you want to archive this gallery?
			</template>
		</card-dialog>

	</div>
</template>

<script>
import PageBar from '@/components/global/PageBar'
import {mapActions, mapGetters} from 'vuex'
import CardDialog from '@/components/global/CardDialog'

export default {
	name: 'GalleryIndex',
	components: {CardDialog, PageBar},
	data: () => ({
		headers: [
			{text: 'Name', value: 'name', align: 'left'},
			{text: 'Description', value: 'description'},
			{text: 'Active', value: 'active'},
			{text: 'Maintainer', value: 'lastMaintainedBy'},
			{text: 'Actions', value: 'actions', align: 'end'},
		],
		valid: false,
		gallery: {
			name: null,
			description: null,
		},
		createDialog: false,
		archiveId: null,
	}),
	created() {
		if (this.galleries.length === 0) {
			this.listGalleries()
		}
	},
	methods: {
		...mapActions('gallery/gallery', [
			'listGalleries',
			'createGallery',
			'archiveGallery'
		]),
	},
	computed: {
		...mapGetters('gallery/gallery', [
			'galleries',
			'listStatus',
			'createStatus',
			'archiveStatus'
		])
	},
	watch: {
		createDialog(updated) {
			if (!updated) {
				this.$refs.galleryForm.reset()
				this.$refs.galleryForm.resetValidation()
			}
		},
		createStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listGalleries()
				this.createDialog = false
			}
		},
		archiveStatus(updated) {
			if (updated === this.status.SUCCEEDED) {
				this.listGalleries()
				this.archiveId = null
			}
		}
	}
}
</script>

<style scoped>

</style>
